import { Box, TextField } from '@mui/material';
import {
  QuoteStatus,
  RuleModeValue,
  ShippersToAllowBidAfterRespondByElapsed,
  SOURCE_PORTAL_DID_NOT_RETURN_THE_ZIP_CODE,
  TabName,
} from '../../../../constants';
import { LookUpRate, WarningItem } from '../../../../components';
import { SuggestedBid } from '../suggestedBid/SuggestedBid';
import { FreightQuoteActionModel } from '../../../../types';
import { is, priceFormat } from '../../../../utils';
import { useBidErrorNotification } from '../../SubmitBidDialog.hooks';
import { ChangeEvent, PropsWithChildren } from 'react';

interface Props {
  smallSize?: boolean;
  disabled?: boolean;
  quote: FreightQuoteActionModel;
  currentTabName: TabName | undefined;
  wrongBidPerMile: boolean;
  commentValue: string;
  commentError?: string | null;
  count?: number;
  onCommentChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: () => void;
  setSuggestedBid: (bid: number | string) => void;
}

export function CommonSubmitContent({
  smallSize,
  quote,
  disabled,
  currentTabName,
  wrongBidPerMile,
  setSuggestedBid,
  commentValue,
  commentError,
  count,
  onCommentChange,
  onBlur,
  children,
}: PropsWithChildren<Props>): JSX.Element {
  const isTimePassed =
    !!quote.respondByTimer &&
    !quote.respondByTimer.minutesLeft &&
    ShippersToAllowBidAfterRespondByElapsed.indexOf(quote.Shipper) == -1;
  const disableForm =
    isTimePassed ||
    (currentTabName === TabName.Open &&
      quote.Status !== QuoteStatus.Open &&
      quote.Status !== QuoteStatus.Ignored &&
      quote.Status !== QuoteStatus.SubmitRequestFailed);
  const zipCodesFromPortal = !!quote.HasZipCodesFromPortal;
  const isBulk = (count ?? 0) >= 2;
  const fuel = !is.nullOrUndefined(quote.Fuel) && !isBulk && (
    <LookUpRate title="Fuel" value={priceFormat(quote.Fuel)} />
  );

  const notification = useBidErrorNotification(
    quote,
    wrongBidPerMile,
    disableForm,
    isTimePassed,
    currentTabName,
  );

  const renderNotification = (message: string | null) => (
    <Box paddingTop={1}>
      <WarningItem>{message}</WarningItem>
    </Box>
  );

  const suggestedBid =
    (!!quote.AutomatedProcessing &&
      quote.AutomatedProcessing.RuleMode === RuleModeValue.Suggest &&
      quote.AutomatedProcessing.SuggestedBid) ||
    null;

  return (
    <Box>
      {!zipCodesFromPortal &&
        renderNotification(SOURCE_PORTAL_DID_NOT_RETURN_THE_ZIP_CODE)}
      {notification && renderNotification(notification)}

      {(children || fuel) && (
        <Box paddingTop={1}>
          {children}
          {fuel}
        </Box>
      )}

      {!!quote.AutomatedProcessing &&
        quote.AutomatedProcessing.RuleMode === RuleModeValue.Suggest &&
        !isBulk && (
          <Box paddingTop={1}>
            <SuggestedBid
              disabled={disableForm || disabled}
              align={smallSize ? 'vertical' : 'horizontal'}
              bid={suggestedBid ? Number(suggestedBid) : 0}
              onApply={() => setSuggestedBid(suggestedBid || '')}
            />
          </Box>
        )}

      <Box paddingTop={2}>
        <TextField
          fullWidth
          tabIndex={-1}
          disabled={disableForm || disabled}
          label="Comments"
          name="comments"
          placeholder="Enter comments"
          id="bid-input"
          multiline
          maxRows={7}
          variant="standard"
          value={commentValue}
          onChange={onCommentChange}
          onBlur={onBlur}
          error={!!commentError}
          helperText={commentError || undefined}
          InputLabelProps={{
            tabIndex: -1,
            shrink: true,
          }}
          inputProps={{
            tabIndex: -1,
          }}
          InputProps={{
            tabIndex: -1,
          }}
        />
      </Box>
    </Box>
  );
}
